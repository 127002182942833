header {
	position: fixed;
	z-index: 3;
	width: 100%;
	height: 11.6rem;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 2.4rem 1.2rem;
	box-sizing: border-box;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.56) 23%, rgba(0, 0, 0, 0));

	.menus {
		display: flex;
		flex-direction: row;
		font-family: Barlow;
		font-size: 16px;
		font-weight: 600;
		align-items: center;
		text-transform: uppercase;

		&-item {
			cursor: pointer;
			margin-right: 2.4rem;

			a {
				color: #bfc6ce;

				&:hover,
				&.active {
					color: #fff;
				}
			}

			.demo-btn {
				padding: 16px 30px;
				border-radius: 34px;
				border: solid 1px #b8a672;
				background-image: linear-gradient(94deg, #ffe8a6 10%, #efb84e 95%);
				box-sizing: border-box;
				color: #4b3610;
				font-size: 16px;
				font-weight: 800;
			}
		}
	}

	.logo-container {
		position: relative;
		height: 6.8rem;
		margin: 0 1.9rem;

		img {
			position: absolute;
			width: 16.8rem;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}

		@media screen and (max-width: 768px) {
			img {
				width: 12.8rem;
			}
		}		
		@media screen and (max-width: 429px) {
			img {
				width: 10.8rem;
			}
		}		
	}
}