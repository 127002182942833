.not-found-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    text-transform: uppercase;
    text-align: center;
    align-items: center;
    overflow: hidden;
    background-image: url('/assets/images/desktop/bg-1.png');

    &:after {
        content: '';
        background-color: #000;
        opacity: 0.8;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .title {
        font-family: 'BarlowBold';
        font-size: 108px;
        color: #fff;
        text-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.85);
        /* Create the gradient. */
        background-image: linear-gradient(45deg, #e5ab36, #ddb663, #FFE8B7, #d3ab58, #c3922e);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        z-index: 1;
    }

    .sub-title {
        font-size: 24px;
        color: #fff;
        margin-bottom: 24px;
        z-index: 1;
    }
}

@media screen and (max-width: 768px) {
    .not-found-wrapper {
        width: auto;
        height: 100%;

        .title {
            font-size: 68px;
        }

        .sub-title {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 429px) {
    .not-found-wrapper {
        width: auto;
        height: 100%;

        .title {
            font-size: 48px;
        }

        .sub-title {
            font-size: 12px;
        }
    }

}