.mobile {
    box-shadow: none;

    .cont {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 50px;
        align-items: center;
    }

    .demo-btn {
        padding: 12px 16px;
        border-radius: 34px;
        border: solid 1px #b8a672;
        background-image: linear-gradient(94deg, #ffe8a6 10%, #efb84e 95%);
        box-sizing: border-box;
        color: #4b3610;
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        cursor: pointer;
    }

    &-logo {
        display: block;
        width: 10%;
        min-width: 120px;
        vertical-align: middle;

        img {
            display: block;
            object-fit: contain;
            width: 100%;
        }
    }

}


.menu {
    $root: &;

    &__burger {
        $burger: #{$root}__burger;
        width: 32px;
        height: 32px;
        right: 10px;
        bottom: 18px;
        cursor: pointer;
        z-index: 99;
        background-image: url('~@/assets/images/burger_bg.png');
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        position: absolute;
        z-index: 5000;
        margin: 0;

        &__span {
            position: absolute;
            height: 2px;
            width: inherit;
            background-color: #24272e;
            transition: all 0.2s;
            width: 19px;

            &:before {
                @extend #{$burger}__span;
                content: '';
                top: -5px;
                left: 3px;
                width: 14px;
            }

            &:after {
                @extend #{$burger}__span;
                content: '';
                top: 5px;
                left: 3px;
                width: 14px;
            }
        }

        &__open {
            background-color: transparent;

            &:before,
            &:after {
                content: '';
                background-color: #24272e;
                transform-origin: 0 50%;
            }

            &:before {
                transform: translateX(1px) translateY(0px) rotate(45deg);
            }

            &:after {
                transform: translateX(1px) translateY(0px) rotate(-45deg);
            }
        }

    }
}

.hamburger-menu {
    width: 100%;
    position: relative;

    &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.82;
        z-index: 1;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(209deg, #000 84%, #000 8%);
    }


    &-container {
        display: flex;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        position: relative;
        z-index: 2;
        padding: 120px 24px 0 0;
        box-sizing: border-box;

        a {
            font-size: 28px;
            text-align: right;
            color: #bfc6ce;
            margin-bottom: 32px;

            &.active {
                color: #fff;
            }
        }

        .mobile-language {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: end;
        }
    }
}