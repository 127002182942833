.dropdowns-container {
    position: relative;
    display: flex;
    justify-content: flex-start;

    .dropdowns {
        &__item {
            &__wrapper {
                display: flex;

                &.hide {
                    // display: none;
                }

                &__content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    padding: 0 8px;
                    position: relative;

                    &__title {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
                        -webkit-touch-callout: none;
                        /* iOS Safari */
                        -webkit-user-select: none;
                        /* Safari */
                        -khtml-user-select: none;
                        /* Konqueror HTML */
                        -moz-user-select: none;
                        /* Old versions of Firefox */
                        -ms-user-select: none;
                        /* Internet Explorer/Edge */
                        user-select: none;
                        /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
                        -webkit-tap-highlight-color: transparent;

                        &>img {
                            // https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
                            -webkit-touch-callout: none;
                            /* iOS Safari */
                            -webkit-user-select: none;
                            /* Safari */
                            -khtml-user-select: none;
                            /* Konqueror HTML */
                            -moz-user-select: none;
                            /* Old versions of Firefox */
                            -ms-user-select: none;
                            /* Internet Explorer/Edge */
                            user-select: none;
                            /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
                            -webkit-tap-highlight-color: transparent;
                        }
                    }

                    >.icon {
                        margin-right: 4px;
                    }

                    .txt {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        min-width: 60px;
                        font-size: 15px;
                        line-height: 1.2;
                        font-weight: 500;
                        color: #fff;
                        text-transform: uppercase;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-left: 12px;

                        &::after {
                            content: '';
                            display: block;
                            width: 6px;
                            height: 6px;
                            margin: 8px 0 8px 8px;
                            transform: scale(0.6) rotate(135deg);
                            -webkit-transform: scale(0.6) rotate(135deg);
                            transition: all 0.1s ease-in;
                            border: 2px solid #fff;
                            border-bottom: none;
                            border-left: none;
                        }

                        &:hover {
                            &::after {
                                -ms-transform: scale(0.6) rotate(-45deg);
                                transform: scale(0.6) rotate(-45deg);
                                -webkit-transform: scale(0.6) rotate(-45deg);
                                transition: all 0.1s ease-in;
                            }
                        }
                    }

                    ul {
                        // https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
                        -webkit-touch-callout: none;
                        /* iOS Safari */
                        -webkit-user-select: none;
                        /* Safari */
                        -khtml-user-select: none;
                        /* Konqueror HTML */
                        -moz-user-select: none;
                        /* Old versions of Firefox */
                        -ms-user-select: none;
                        /* Internet Explorer/Edge */
                        user-select: none;
                        /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
                        -webkit-tap-highlight-color: transparent;
                        display: none;
                        position: absolute;
                        margin: 0;
                        padding: 0;
                        border-radius: 2px;
                        box-sizing: border-box;
                        border-radius: 8px;
                        -webkit-backdrop-filter: blur(7.5px);
                        backdrop-filter: blur(7.5px);
                        background-color: #102741;
                        top: 100%;
                        left: 0;
                        z-index: 999;
                        width: 100%;
                        min-width: 150px;

                        &.up {
                            top: unset;
                            bottom: 100%;
                        }

                        li {
                            list-style: none;
                            padding: 12px;

                            display: flex;
                            justify-content: flex-start;
                            flex-direction: row;
                            align-items: center;
                            height: 24px;
                            font-size: 15px;
                            color: #fff;
                            text-transform: uppercase;

                            img {
                                // https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
                                -webkit-touch-callout: none;
                                /* iOS Safari */
                                -webkit-user-select: none;
                                /* Safari */
                                -khtml-user-select: none;
                                /* Konqueror HTML */
                                -moz-user-select: none;
                                /* Old versions of Firefox */
                                -ms-user-select: none;
                                /* Internet Explorer/Edge */
                                user-select: none;
                                /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
                                -webkit-tap-highlight-color: transparent;
                                margin-right: 12px;
                            }

                            .country-label {
                                line-height: 16px;
                            }

                            &:hover {
                                color: #fff;
                                background-image: linear-gradient(to right,
                                        rgba(91, 134, 175, 0.5),
                                        rgba(91, 134, 175, 0.5) 43%,
                                        rgba(108, 110, 115, 0) 96%);
                            }

                            &.active {
                                color: #fff;
                                background-image: linear-gradient(to right,
                                        rgba(91, 134, 175, 0.5),
                                        rgba(91, 134, 175, 0.5) 43%,
                                        rgba(108, 110, 115, 0) 96%);
                            }
                        }
                    }

                    &:hover {
                        background-image: linear-gradient(to right,
                                rgba(91, 134, 175, 0.5),
                                rgba(91, 134, 175, 0.5) 43%,
                                rgba(108, 110, 115, 0) 96%);
                    }
                }

                &__con:last-child {
                    padding-right: 0;
                }

                &:hover {
                    ul {
                        display: block;
                    }
                }
            }
        }
    }
}