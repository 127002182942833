.g-heading {
    .heading {
        margin-bottom: 0.9rem;
        color: #26325c;
        font-size: 3.6rem;
        font-family: BarlowBold;
        font-weight: bold;
        text-transform: uppercase;
    }
    .sub-heading {
        display: inline-block;
        margin-bottom: 0.5rem;
        padding: 0 1rem;
        font-size: 1.6rem;
        letter-spacing: 1.6px;
        line-height: 2;
        color: #fff;
        font-family: Barlow;
        background-color: #f4303f;
        text-transform: uppercase;
    }
}