.features {
    width: 100%;
    padding: 6.6rem 2.4rem 10rem;
    background-color: #0d0d0d;
    box-sizing: border-box;

    &-wrap {
        display: flex;
        width: 100%;
        max-width: 144rem;
        margin: auto;
        align-items: center;
        justify-content: space-between;
    }

    .about {
        &-img {
            position: relative;
            width: 35%;
            text-align: right;
            box-sizing: border-box;

            .cf-bg {
                width: 100%;
            }
        }

        &-contents {
            width: 65%;
            text-align: left;
            box-sizing: border-box;

            .heading {
                font-size: 48px;
                font-weight: 800;
                text-align: left;
                color: #fff;
            }

            .desc {
                font-size: 24px;
                font-weight: 500;
                text-align: left;
                color: #bfc6ce;
                margin-bottom: 24px;
                text-align: justify;

                span {
                    font-weight: bold;
                }
            }

            .key-points {
                display: flex;
                width: 100%;
                flex-direction: row;

                &-item {
                    padding: 24px 24px 26px;
                    border-radius: 16px;
                    background-color: #1d1d1d;
                    margin-right: 12px;
                    min-height: 360px;

                    img {
                        width: 64px;
                        height: 64px;
                        margin-bottom: 12px;
                    }

                    .sub-title {
                        font-size: 28px;
                        font-weight: bold;
                        text-align: left;
                        color: #e5bf5b;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                    }

                    .desc {
                        font-size: 20px;
                        text-align: left;
                        color: #bfc6ce;
                    }
                }
            }
        }
    }


    @keyframes up_down {
        from {
            transform: translateY(5%)
        }

        to {
            transform: translateY(0)
        }
    }

    &.mobile {
        .features-wrap {
            max-width: 100%;
        }

        .about {
            &-img {
                width: 70%;
                margin: 24px auto;

                .cf-bg {
                    width: 100%;
                }
            }

            &-contents {
                width: 100%;

                .key-points {
                    flex-direction: column;

                    &-item {
                        min-height: 60px;
                        margin-bottom: 24px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .sub-title {
                            text-align: center;
                        }

                        .desc {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .heading {
        font-size: 24px !important;
    }

    .sub-title {
        font-size: 20px !important;
    }

    .desc {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 429px) {
    .heading {
        font-size: 22px !important;
    }

    .sub-title {
        font-size: 18px !important;
    }

    .desc {
        font-size: 12px !important;
    }
}