.home-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    max-height: 100%;
    aspect-ratio: 16/9;
    text-transform: uppercase;
    text-align: center;
    align-items: center;

    @supports not (aspect-ratio: 16/ 9) {
        &::before {
            float: left;
            padding-top: 56.25%;
            content: '';
        }

        &::after {
            display: block;
            content: '';
            clear: both;
        }
    }

    .slick-slider {
        height: 100%;
        width: 100%;

        .slick-next,
        .slick-prev {
            display: none !important;
        }
        .slick-list {
            height: 100%;
            .slick-track {
                height: 100%;
            }
        }
        .slick-slide {
            position: relative;
            width: 100%;
            height: 100%;

            .cover {
                width: 100%;
            }

            .contents {
                position: absolute;

                &.slide-1,
                &.slide-2,
                &.slide-2-zh,
                &.slide-3,
                &.slide-4 {
                    img {
                        opacity: 0;
                        transition: 0.6s;
                        height: 100%;
                        width: 100%;
                    }
                }

                &.slide-1 {
                    bottom: 15%;
                    left: 50%;
                    max-width: 80%;
                    width: 70%;
                    transform: translateX(-50%);
                }

                &.slide-2 {
                    bottom: 50%;
                    right: 50px;
                    width: 45%;
                    transform: translateY(50%);
                }
            }

            &.slick-active {
                .contents {

                    &.slide-1,
                    &.slide-2,
                    &.slide-2-zh,
                    &.slide-3,
                    &.slide-4 {
                        img {
                            opacity: 1;
                        }
                    }

                    &.slide-1 {
                        .heading {
                            left: 0;
                        }

                        .sub-heading {
                            right: 0;
                        }
                    }

                    &.slide-2 {
                        .heading {
                            left: 2%;
                        }

                        .sub-heading {
                            left: -2%;
                        }
                    }

                }
            }
        }

        .slick-dots {
            bottom: 50px !important;
            height: 35px;
            min-width: 10% !important;
            left: 50%;
            transform: translateX(-50%);

            li {
                opacity: 0.5;
                width: 15% !important;
                min-width: 48px !important;
                max-width: 60px !important;
                height: 6px !important;
                border-radius: 4px;
                background: white !important;
                margin: 0 4px !important;

                button {
                    display: block;
                    height: 100% !important;
                    width: 100% !important;

                    &::before {
                        content: '';
                        opacity: 0;
                    }
                }

            }

            .slick {
                &-active {
                    opacity: 1;
                }
            }
        }
    }


}

@media screen and (max-width: 768px) {
    .home-wrapper {
        width: 100vw;
        height: 100vh;
        justify-content: flex-end;
        box-sizing: border-box;

        .slick-slide {
            position: relative;
            width: 100%;
            height: 100%;

            > div {
                height: 100%;

                > div {
                    height: 100%;
                }
            }
            .banner-wrapper {
                height: 100%;

                img {
                    height: 100%;
                }
                .slide-1 {
                    bottom: 20% !important;
                    left: 50% !important;
                    width: 100%!important ;
                    transform: translateX(-50%) !important;
                }
                .slide-2 {
                    bottom: 10%  !important;
                    right: 50% !important;
                    width: 85% !important;
                    transform: translateX(50%) !important;
                }
            }
        }
        .slick-slider {
            .slick-dots {
                bottom: 20px !important;

                li { 
                    height: 4px !important;
                }
            }
        }
    }
}
