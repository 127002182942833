@import './reset.scss';
@import './fonts.scss';
@import './general.scss';


html,
body {
	font-size: 10px;
	font-family: Barlow;
	width: 100%;
	height: 100%;

	#root {
		width: 100%;
		height: 100%;
	}

	div {
		// https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
		-webkit-touch-callout: none;
		/* iOS Safari */
		-webkit-user-select: none;
		/* Safari */
		-khtml-user-select: none;
		/* Konqueror HTML */
		-moz-user-select: none;
		/* Old versions of Firefox */
		-ms-user-select: none;
		/* Internet Explorer/Edge */
		user-select: none;
		/* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
		-webkit-tap-highlight-color: transparent;
		user-drag: none;
		-webkit-user-drag: none;
	}
}

.clickable {
	cursor: pointer;
}