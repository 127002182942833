.exclusive {
    width: 100%;
    padding: 4rem 0;
    background-color: #fff;
    &-wrap {
        display: flex;
        width: 100%;
        max-width: 90rem;
        margin: 0 auto;
    }
    &-title {
        display: flex;
        justify-content: flex-end;
        width: 20rem;
        .details {
            width: 18rem;
            .heading {
                margin-bottom: 0.4rem;
            }
            .desc {
                font-size: 0.8rem;
                font-family: Barlow;
                color: #26325c;
                text-align: justify;
                line-height: 2;
                span {
                    font-weight: bold;
                }
            }
            button {
                width: 15rem;
                height: 4rem;
                margin-top: 0.8rem;
                outline: none;
                border: 1.5px solid #f4303f;
                background-color: transparent;
                font-family: BarlowBold;
                font-size: 1.6rem;
                color: #f4303f;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
    }
    &-contents {
        overflow: visible;
        width: calc(100% - 20rem);
        padding-left: 3rem;
        box-sizing: border-box;

        .slick-list {
            overflow: visible;
            .slick-slide {
                padding: 0 0.3rem;
                box-sizing: border-box;
            }
        }

        .exclusive-block {
            max-width: 16rem;
            width: 100%;
            height: 21.9rem;
            transition: all .2s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
            .thumb {
                width: 100%;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
            .desc {
                min-height: 9.7rem;
                padding: 1.2rem;
                box-sizing: border-box;
                box-shadow: 0 1.5px 15px 0 rgba(152, 161, 190, 0.2);
                p {
                    font-size: 0.8rem;
                    color: #616784;
                }
            }
        }
    }
}