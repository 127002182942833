.services {
    padding: 4rem 0;
    background-color: #f5f8fa;
    &-wrap {
        max-width: 77.2rem;
        width: 100%;
        margin: auto;
        .title {
            text-align: center;
        }
        .blocks {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            .block {
                width: 25%;
                padding: 0.6rem;
                text-align: center;
                box-sizing: border-box;
                transition: all .2s ease-in-out;
                &:hover {
                    transform: scale(1.1);
                }
                &-wrap {
                    width: 100%;
                    height: 100%;
                    padding: 1.6rem;
                    box-sizing: border-box;
                    background-color: #fff;
                    border-radius: 10px;
                    box-shadow: 0 1.5px 15px 0 rgba(152, 161, 190, 0.2);
                    img {
                        width: 4rem;
                        height: 4rem;
                        margin-bottom: 0.8rem;
                    }
                    h3 {
                        min-height: 5rem;
                        padding: 0 1rem;
                        margin-bottom: 1rem;
                        font-family: BarlowBold;
                        font-size: 1.6rem;
                        color: #26325c;
                    }
                    p {
                        font-size: 1.2rem;
                        color: #26325c;
                    }
                }
                
            }
        }
    }
}