@font-face {
    font-family: "Barlow";
    src: url("../assets/fonts/Barlow-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "BarlowBold";
    src: url("../assets/fonts/Barlow-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Saira";
    src: url("../assets/fonts/Saira-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SairaBold";
    src: url("../assets/fonts/Saira-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SairaExtraBold";
    src: url("../assets/fonts/Saira-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "SairaMedium";
    src: url("../assets/fonts/Saira-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "SF TransRobotics";
    src: url("../assets/fonts/SF TransRobotics.ttf") format("truetype");
}

@font-face {
    font-family: "SF TransRobotics Bold";
    src: url("../assets/fonts/SF TransRobotics Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SF TransRobotics Extended Bold";
    src: url("../assets/fonts/SF TransRobotics Extended Bold.ttf") format("truetype");
}
