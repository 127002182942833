.lootbox {
    &-title {
        position: relative;
        h2 {
            position: absolute;
            top: 3.6rem;
            left: 0;
            right: 0;
            margin: auto;
            font-family: BarlowBold;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            span {
                display: block;
                &.sm {
                    font-size: 1.2rem;
                }
                &.lg {
                    font-size: 3.6rem;
                }
            }
        }
        img {
            width: 100%;
        }
    }
    &-features {
        position: relative;
        background-color: #f5f8fa;
        &-wrap {
            position: relative;
            padding: 9.9rem 8.1rem;
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;
            max-width: 90rem;
            margin: auto;
        }
        .features {
            &-device {
                position: absolute;
                top: 17%;
                left: 0;
                right: 0;
                width: 30rem;
                margin: auto;
                img {
                    width: 100%;
                }
            }
            &-list {
                margin: 0;
                font-size: 1.6rem;
                color: #26325c;
                li {
                    display: flex;
                    align-content: center;
                    max-width: 25.5rem;
                    margin-bottom: 3.9rem;
                    &:before {
                        content: '';
                        display: block;
                        min-width: 5.5rem;
                        min-height: 5.5rem;
                        width: 5.5rem;
                        height: 5.5rem;
                    }
                }
                &.left {
                    margin-right: 15%;
                    li {
                        text-align: left;
                        &:nth-child(1) {
                            &:before {
                                margin-right: 1.2rem;
                                background-image: url('/assets/images/feature-icon-1.png');
                                background-size: 100% 100%;
                            }
                        }
                        &:nth-child(2) {
                            &:before {
                                margin-right: 1.2rem;
                                background-image: url('/assets/images/feature-icon-2.png');
                                background-size: 100% 100%;
                            }
                        }
                        &:nth-child(3) {
                            &:before {
                                margin-right: 1.2rem;
                                background-image: url('/assets/images/feature-icon-3.png');
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
                &.right {
                    margin-left: 15%;
                    li {
                        flex-direction: row-reverse;
                        text-align: right;
                        &:nth-child(1) {
                            &:before {
                                margin-left: 1.2rem;
                                background-image: url('/assets/images/feature-icon-4.png');
                                background-size: 100% 100%;
                            }
                        }
                        &:nth-child(2) {
                            &:before {
                                margin-left: 1.2rem;
                                background-image: url('/assets/images/feature-icon-5.png');
                                background-size: 100% 100%;
                            }
                        }
                        &:nth-child(3) {
                            &:before {
                                margin-left: 1.2rem;
                                background-image: url('/assets/images/feature-icon-6.png');
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}