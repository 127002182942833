.contact-box {
  width: 100%;
  padding: 6.6rem 2.4rem 10rem;
  background-color: #0d0d0d;
  transition: .5s ease;
  box-sizing: border-box;

  &-wrap {
    display: flex;
    width: 100%;
    margin: auto;
    flex-direction: column;

    .heading {
      font-family: BarlowBold;
      font-size: 40px;
      letter-spacing: 0.4px;
      text-align: left;
      color: #e5bf5b;
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    .sub-heading,
    .message {
      font-family: Barlow;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.24px;
      text-align: left;
      color: #fff;
      margin-bottom: 32px;
    }

    .div_succes {
      text-align: center;

      h2 {
        text-align: center;
      }
    }

    form {
      display: table;
      margin: auto;
      max-width: 700px;
      width: 100%;

      .code {
        background: transparent;
        text-align: left;
        position: absolute;
        left: 35px;
        top: 0;
        transform: translate(10px, 8px);
        width: 42px;
        color: #a3b4c7;
        line-height: 2;
        padding: 0;
        font-size: 12px;
      }

      small {
        color: red;
        font-size: 11px;
      }

      .row:after {
        content: "";
        display: table;
        clear: both;
      }

      input,
      textarea {
        padding: 10px;
        border-radius: 4px;
        border: transparent;
        margin-bottom: 10px;
        outline: none;
        resize: vertical;
        width: 100%;
        font-family: Barlow;
        font-size: 20px;
        letter-spacing: 0.2px;
        text-align: left;
        color: #bfc6ce;
        background-color: transparent;
        border-bottom: 2px solid #1d1d1d;

        &::-ms-input-placeholder {
          /* Edge 12-18 */
          font-size: 20px;
        }

        &::placeholder {
          font-size: 20px;
        }
      }

      label {
        color: #bfc6ce;
      }

      .btn-div {
        width: 100%;
        margin: auto;
        margin-bottom: 30px;

      }


      textarea:focus {
        outline: none !important;
        border: 1px solid #26325c;
      }

      .input:focus {
        outline: none !important;
        border: 1px solid red;
        box-shadow: 0 0 10px #719ece;
      }

      input[type="text"]:focus {
        border: 1px solid #26325c;
      }

      .invalid {
        border: 1px solid red;
      }

      .invalid:focus {
        border: 1px solid red;
      }

      ::placeholder {
        color: #9caec3;
        opacity: 1;
        font-size: 12px;
      }

      .details {
        width: 100%;
        margin: auto;
        max-width: 700px;

        .contact-details {
          text-transform: none;
          font-size: 11px;
        }

        span {
          width: 100%;
          height: 14px;
          font-family: Saira;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: left;
          color: #9caec3;
          text-transform: capitalize;
        }

        .red {
          color: red;
        }

        .form-phone {
          background-color: #f0f2f7;
          border-radius: 4px;
          height: 125px;
          margin-bottom: 10px;
          resize: vertical;
          padding: 15px;
          width: 98%;

          label {
            font-size: 12px;
            color: #9caec3;
            text-transform: capitalize;
          }

          .phone {
            transform: translate(0px, 5px);

            .container {
              display: flex;

              .inline {
                display: inline-block;

                .PhoneInput {
                  .PhoneInputCountry {
                    background-color: white;
                    width: 40px;
                    height: 20px;
                    padding: 10px;
                    border-radius: 4px;
                  }
                }

                .PhoneInputInput {
                  flex: none !important;
                  min-width: 0 !important;
                  width: 88% !important;
                  left: 5px;
                }

                .react-tel-input {
                  display: inline !important;
                  font-family: Saira !important;
                  color: #9caec3;

                  .form-control {
                    max-width: 900px;
                    padding-left: 10px !important;
                    border: transparent !important;
                    color: #26325c !important;
                    opacity: 1 !important;
                    font-size: 12px !important;
                    height: 39px !important;
                    resize: vertical !important;
                    width: 81% !important;
                    margin-bottom: 12px !important;
                    left: 121px;
                  }

                  .flag-dropdown {
                    background-color: white !important;
                    border: transparent !important;
                    padding: 5px !important;
                    height: 37px !important;
                    top: -6px !important;
                  }

                  .selected-flag .arrow {
                    left: 79px !important;
                  }

                  .selected-flag {
                    width: 98px !important;
                  }

                  .flag-dropdown.open .selected-flag {
                    background: transparent !important;
                    border-radius: 3px 0 0 0;
                  }
                }

                .react-tel-input .react-tel-input .selected-flag:hover,
                .react-tel-input .selected-flag:focus {
                  background: transparent !important;
                }
              }

              .inline:first-child {
                width: 21%;
              }

              .inline:last-child {
                width: 100%;
              }
            }

            .country-code {
              width: 60px;
              margin-right: 10px;
            }

            .number {
              width: 94%;
              margin-left: 10px;
              resize: vertical;
            }
          }

          .skypeTelegram {
            display: flex;

            .width-size {
              display: inline-block;
            }

            .width-size:first-child {
              width: 80%;
              margin-right: 10px;
            }

            .width-size:last-child {
              width: 80%;
            }
          }
        }
      }
    }

    button {
      padding: 16px 55px;
      border-radius: 34px;
      border: solid 1px #b8a672;
      background-image: linear-gradient(94deg, #ffe8a6 10%, #efb84e 95%);
      font-family: Barlow;
      font-size: 20px;
      font-weight: 800;
      text-align: center;
      color: #4b3610;
      cursor: pointer;
      text-transform: uppercase;
    }

    button:hover {
      color: #fff;
    }
  }
}

@media screen and (max-width: 768px) {

  .col-25,
  .col-75,
  input[type="text"] {
    width: 100%;
    margin-top: 0;
  }

  .sub-heading,
  .message {
    font-size: 20px !important;
  }

  input,
  textarea,
  textarea::placeholder,
  input::placeholder {
    font-size: 16px !important;
    max-width: 90%;
  }

  button {
    padding: 12px 32px !important;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 429px) {

  .sub-heading,
  .message {
    font-size: 16px !important;
  }

  input,
  textarea,
  textarea::placeholder,
  input::placeholder {
    font-size: 14px !important;
    max-width: 90%;
  }
  button {
    padding: 12px 22px !important;
    font-size: 14px !important;
  }
}

.PhoneInputCountryIcon {
  width: 30px !important;
  height: 20px !important;
}