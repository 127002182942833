.about-us {
    &-bg {
        width: 100%;
        position: absolute;
        height: 100%;
        bottom: 0;
        top: 0;
        left: 0;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 15rem 0;
        position: relative;
        background-color: #0d0d0d;


        .chiken {
            width: 20vw;

            &.ch-1 {
                position: absolute;
                left: 0;
                bottom: 0;

                img {
                    width: 100%;
                }
            }

            &.ch-2 {
                position: absolute;
                right: 0;
                bottom: 0;

                img {
                    width: 100%;
                }
            }
        }
    }

    &-text-wrapper {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;

        .title {
            text-align: center;
            font-family: 'SF TransRobotics';
            font-size: 88px;
            color: #fff;
            text-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.85);
            /* Create the gradient. */
            background-image: linear-gradient(45deg, #e5ab36, #ddb663, #FFE8B7, #d3ab58, #c3922e);
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            text-transform: uppercase;
            margin-bottom: 12px;
        }

        .sub-title {
            font-size: 24px;
            color: #fff;
            margin-bottom: 36px;
            text-transform: uppercase;
        }

        .desc {
            text-align: justify;
            width: 80%;
            font-family: Barlow;
            font-size: 22px;
            font-weight: 500;
            color: #bfc6ce;
        }
    }
}

@media screen and (max-width: 768px) {
    .about-us {
        &-bg {
            width: 100%;
            position: absolute;
            height: 100%;
            bottom: 0;
            top: 0;
            left: 0;
        }

        &-wrapper {
            width: 100%;
            padding: 6.6rem 2.4rem 10rem;
            box-sizing: border-box;
            min-height: 50vh;

            .chiken {
                width: 30%;
            }
        }

        &-text-wrapper {
            width: 100%;

            .title {
                font-size: 58px;
            }

            .sub-title {
                font-size: 20px;
            }

            .desc {
                text-align: justify;
                width: 100%;
                font-family: Barlow;
                font-size: 14px;
                font-weight: 500;
                color: #bfc6ce;
            }
        }
    }
}

@media screen and (max-width: 429px) {
    .about-us {
        &-bg {
            width: 100%;
            position: absolute;
            height: 100%;
            bottom: 0;
            top: 0;
            left: 0;
        }

        &-wrapper {
            width: 100%;
            padding: 6.6rem 2.4rem 10rem;
            box-sizing: border-box;
            min-height: 50vh;

            .chiken {
                width: 30%;
            }
        }

        &-text-wrapper {
            width: 100%;

            .title {
                font-size: 38px;
            }

            .sub-title {
                font-size: 16px;
            }

            .desc {
                width: 100%;
            }
        }
    }
}