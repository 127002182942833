.footer {
	&-wrapper {
		width: 100%;
		height: 66px;
		background-color: #0d0d0d;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: Barlow;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0.2px;
		color: #bfc6ce;

		@media screen and (max-width: 768px) {
			font-size: 16px;
		}

		@media screen and (max-width: 429px) {
			font-size: 12px;
		}

	}
}